import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Markdown from "../components/Markdown"
import "./about.css"

const createSecretariat = ({ node }) => {
  return (
    <div className="secretariat__container sm:flex-1 lg:flex-none lg:max-w-6xl mb-8 mx-3 lg:mx-auto bg-white" key={node.id}>
      <div className="shadow-md">
        <h3 className="text-xl xs:text-2xl lg:text-3xl py-3 px-6 text-white" style={{ "backgroundColor": "#9F90CD" }}>
          {node.frontmatter.name}<span className="text-purple-200"><span
          className="px-6">/</span>{node.frontmatter.title}</span>
        </h3>
        <div className="lg:flex">
          <div className="secretariat__image mx-auto mt-8 lg:m-0 lg:self-stretch">
            {node.frontmatter.image &&
            <Img fluid={node.frontmatter.image.childImageSharp.fluid}
                 alt={node.frontmatter.title}
                 className="secretariat__image lg:h-full rounded-full lg:rounded-none" />}
          </div>

          <div className="p-8 lg:flex-1 lg:self-stretch">
            <Markdown className="text-gray-900" content={node.body} />
          </div>
        </div>
      </div>
    </div>
  )
}

const AboutPage = (props) => {
  const {
    data: {
      allMdx: { edges },
    },
  } = props

  return (
    <Layout>
      <SEO title="About Us" />

      <div className="bg-gray-160 min-h-screen-nav">
        <div className="relative px-6 py-10 overflow-hidden bg-purple-800 shadow-xl sm:px-12 sm:py-20 md:py-24 lg:py-32">
          <div
            aria-hidden="true"
            className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
          >
            <svg
              className="absolute inset-0 w-full h-full"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1463 360"
            >
              <path
                className="text-purple-700 text-opacity-40"
                fill="currentColor"
                d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
              />
              <path
                className="text-purple-900 text-opacity-40"
                fill="currentColor"
                d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
              />
            </svg>
          </div>
          <div className="relative">
            <div className="sm:text-center">
              <h1 className="text-6xl font-bold tracking-tight text-white lg:text-8xl">
                About Us
              </h1>
              <p className="max-w-4xl mx-auto mt-6 text-lg text-purple-200 md:mt-8 md:text-2xl">
                South Bay MUN is a MUN conference co-hosted by Monta Vista and Homestead High School. 
                Open to middle school and high school delegates, SBMUN is an opportunity to engage in 
                public speaking, debate, and discuss foreign policy and politics for students of all 
                levels of experience.
              </p>
            </div>
          </div>
        </div>

        <div className="h-8 sm:h-24" />
        <div className="pb-24">          
          <h2 className="mb-8 font-bold text-center text-purple-800 section-header">
            Meet Our 2023-24 Secretariat </h2>
          {edges.map(node => createSecretariat(node))}
        </div>
    </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: {regex: "/(secretariat)/"} },
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          body
          frontmatter {
            name
            title
            image {
              childImageSharp {
                fluid(maxWidth: 400, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            order
          }
        }
      }
    }
  }
`
